var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sceneryCateList"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_vm._v("分销海报列表")])],1),_c('div',{staticClass:"search"},[_c('span',[_vm._v("海报名称")]),_c('el-input',{attrs:{"placeholder":"请输入海报名称"},model:{value:(_vm.list.title),callback:function ($$v) {_vm.$set(_vm.list, "title", $$v)},expression:"list.title"}}),_c('span',[_vm._v("海报类型")]),_c('el-select',{staticStyle:{"margin":"0 20px"},attrs:{"clearable":"","placeholder":"请选择"},model:{value:(_vm.list.type),callback:function ($$v) {_vm.$set(_vm.list, "type", $$v)},expression:"list.type"}},_vm._l((_vm.typeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('span',[_vm._v("海报状态")]),_c('el-select',{staticStyle:{"margin":"0 20px"},attrs:{"clearable":"","placeholder":"请选择"},model:{value:(_vm.list.status),callback:function ($$v) {_vm.$set(_vm.list, "status", $$v)},expression:"list.status"}},_vm._l((_vm.statusList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getPosterList()}}},[_vm._v("查找")])],1),_c('el-table',{attrs:{"data":_vm.tableData,"border":""}},[_c('el-table-column',{attrs:{"align":"center","prop":"dept","label":"排序"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.inputVisible)?_c('el-input',{ref:"saveTagInput",staticClass:"input-new-tag",attrs:{"size":"small"},on:{"input":_vm.onInput,"blur":function($event){return _vm.handleInputConfirm(row)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleInputConfirm(row)}},model:{value:(row.dept),callback:function ($$v) {_vm.$set(row, "dept", $$v)},expression:"row.dept"}}):_c('el-button',{staticClass:"button-new-tag",attrs:{"size":"small"},on:{"click":function($event){return _vm.onChangeSort(row)}}},[_vm._v(_vm._s(row.dept))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"qrId","label":"ID"}}),_c('el-table-column',{attrs:{"align":"center","prop":"title","label":"名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"type","label":"类型"}}),_c('el-table-column',{attrs:{"align":"center","prop":"bgImg","label":"背景图"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticClass:"showimg",attrs:{"preview-src-list":[row.bgImg],"src":row.bgImg}})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"headCoordinate","label":"头像坐标"}}),_c('el-table-column',{attrs:{"align":"center","prop":"headSize","label":"头像大小"}}),_c('el-table-column',{attrs:{"align":"center","prop":"qrcodeCoordinate","label":"二维码坐标"}}),_c('el-table-column',{attrs:{"align":"center","prop":"qrcodeSize","label":"二维码大小"}}),_c('el-table-column',{attrs:{"align":"center","prop":"strStatus","label":"状态"}}),_c('el-table-column',{attrs:{"align":"center","prop":"","label":"操作","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 1 && _vm.$store.state.powerList.indexOf('qr:code:list:update') !== -1)?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){_vm.changeStatus(row.qrId, 2) && _vm.$store.state.powerList.indexOf('qr:code:list:update') !== -1}}},[_vm._v("禁用")]):_vm._e(),(row.status === 2)?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.changeStatus(row.qrId, 1)}}},[_vm._v("启用")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('qr:code:list:add') !== -1
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onEditCalsssA(row)}}},[_vm._v("修改")]):_vm._e()]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.list.currentPage,"page-sizes":[5, 10, 15, 20],"page-size":_vm.list.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pagination.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('el-dialog',{staticClass:"editDialog",attrs:{"title":"编辑分类","visible":_vm.editClassADialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.editClassADialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"海报名称"}},[_c('el-input',{attrs:{"placeholder":"请输入海报名称"},model:{value:(_vm.editClassAForm.title),callback:function ($$v) {_vm.$set(_vm.editClassAForm, "title", $$v)},expression:"editClassAForm.title"}})],1),_c('el-form-item',{attrs:{"label":"海报背景图"}},[(_vm.editClassADialogVisible)?_c('send-image',{attrs:{"images":_vm.editClassAForm.bgImg,"type":"one"},on:{"addImageStr":function (e) { return _vm.addImageStr(e, 2); }}}):_vm._e()],1),_c('el-form-item',[_c('span',[_vm._v("建议尺寸：535*951px")])])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.editClassADialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onEditCateClassAOk}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }